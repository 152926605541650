import * as React from "react"
import { graphql} from 'gatsby';
import { useTranslation, Trans } from 'gatsby-plugin-react-i18next';
import { 
  Backdrop,
  CircularProgress,
} from "@mui/material"
import BeGeneralPage from "../components/be-general-page";

const LoadingPage = (props) => {
	const { t } = useTranslation();

  return (
    <>
      <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color="inherit" />
      </Backdrop>
      <BeGeneralPage title={t("Loading")}>
        
      </BeGeneralPage>
    </>
  )
}

export default LoadingPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["common", "side_bar", "loading"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;